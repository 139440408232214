const devApiEndpoint =
  'http://ec2-18-223-69-14.us-east-2.compute.amazonaws.com:3002';
const prodApiEndpoint = 'https://api.gplife.co';
export const base_URL = prodApiEndpoint;


// export const base_URL = 'http://ec2-18-223-69-14.us-east-2.compute.amazonaws.com:3002'

// client = http://ec2-18-223-69-14.us-east-2.compute.amazonaws.com:3003

// export const base_URL =  'http://localhost:3003'
// 327a57f5-db85-4d46-ac6d-3645813160c5
