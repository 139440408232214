import React from 'react'
import { Action } from 'redux';
import {
    IMAGE_UPLOAD_REQUEST,
    IMAGE_UPLOAD_SUCCESS,
    IMAGE_UPLOAD_ERROR,
    // EventsType,
    ImageUploadDispatchTypes
} from '../constants/ImageUploadActionType'


interface DefaultState {
    loading: boolean,
    imageUpload?: [],
}


const initialState: DefaultState = {
    loading: false,
}


const ImageUploadsReducer = (state: DefaultState = initialState, action: ImageUploadDispatchTypes): DefaultState => {
    switch (action.type) {
        case IMAGE_UPLOAD_REQUEST:
            return {
                // ...state,
                loading: true
            }

        case IMAGE_UPLOAD_SUCCESS:
            return {
                // ...state,
                loading: false,
                imageUpload: action.payload,

            }

        case IMAGE_UPLOAD_ERROR:
            return {
                // ...state,
                loading: false,
                // error: action.payload
            }

        default:
            return state
            break;
    }
}

export default ImageUploadsReducer