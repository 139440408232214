import axios from 'axios';
import { useEffect, useState } from 'react';
import { MemberType } from 'src/redux/constants/MemberActionType';
import { base_URL } from 'src/redux/constants/_URL';

const useMember = (memberId: number) => {
  const [member, setMember] = useState<MemberType>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMember = async (id: number) => {
      setLoading(true);
      try {
        const response = await axios.get(`${base_URL}/members/${id}`);
        if (!response.data) throw new Error('Member not found');
        setMember(response.data);
      } catch (e) {
        setError(e.message);
      }
      setLoading(false);
    };

    fetchMember(memberId);
  }, []);

  return { member, loading, error };
};

export default useMember;
