import React from 'react'
import { Action } from 'redux';
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LoginType,
    LoginTypeDispatchTypes
} from '../constants/LoginTypeAction'


interface DefaultState {
    loading: boolean,
    loginRes: Array<{}>,
    error: string,
}


const initialState: DefaultState = {
    loading: false,
    error: '',
    loginRes: [],
}


const LoginReducer = (state: DefaultState = initialState, action: LoginTypeDispatchTypes): DefaultState => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                loginRes: action.payload,
            }

        case LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return state
            break;
    }
}

export default LoginReducer