import React from 'react'
import { Action } from 'redux';
import {
    GET_RESTAURANT_REQUEST,
    GET_RESTAURANT_SUCCESS,
    GET_RESTAURANT_ERROR,
    RestaurantType,
    RestaurantDispatchTypes
} from '../constants/RestaurantActionType'


interface DefaultState {
    loading: boolean,
    restaurantList?: any,
    totalCount?: any,
}


const initialState: DefaultState = {
    loading: false,
    totalCount: 0,
}


const RestaurantReducer = (state: DefaultState = initialState, action: RestaurantDispatchTypes): DefaultState => {
    switch (action.type) {
        case GET_RESTAURANT_REQUEST:
            return {
                // ...state,
                loading: true
            }

        case GET_RESTAURANT_SUCCESS:
            return {
                // ...state,
                loading: false,
                totalCount: action.totalCount,
                restaurantList: action.payload,

            }

        case GET_RESTAURANT_ERROR:
            return {
                // ...state,
                loading: false,
                // error: action.payload
            }

        default:
            return state
            break;
    }
}

export default RestaurantReducer