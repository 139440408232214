import React from 'react'
import { Action } from 'redux';
import {
    GET_OTP_VALIDATE_REQUEST,
    GET_OTP_VALIDATE_SUCCESS,
    GET_OTP_VALIDATE_ERROR,
    OTPValidateType,
    OTPValidateDispatchTypes
} from '../constants/OTPValidateActionType'


interface DefaultState {
    loading: boolean,
    otpValid?: OTPValidateType,
    otpError?: boolean
}


const initialState: DefaultState = {
    loading: false,
    otpError: false,
}


const OTPValidateReducer = (state: DefaultState = initialState, action: OTPValidateDispatchTypes): DefaultState => {
    switch (action.type) {
        case GET_OTP_VALIDATE_REQUEST:
            return {
                // ...state,
                loading: true
            }

        case GET_OTP_VALIDATE_SUCCESS:
            // debugger
            return {
                // ...state,
                loading: false,
                otpValid: action.payload,

            }

        case GET_OTP_VALIDATE_ERROR:
            // debugger
            return {
                // ...state,
                loading: false,
                otpError: true,
                // error: action.payload
            }

        default:
            return state
            break;
    }
}

export default OTPValidateReducer