import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useRoutes } from 'react-router-dom';
import { RootStore } from 'src/redux/store';
import router from 'src/router';
import MemberModalProvider from './contexts/MemberContext';
import './styles.css';
import ThemeProvider from './theme/ThemeProvider';

function App() {
  const content = useRoutes(router);
  const navigate = useNavigate();

  const { loginRes, error }: any = useSelector(
    (state: RootStore) => state.LoginReducer
  );

  useEffect(() => {
    const loginVaild = localStorage.getItem('login'),location=window.location.pathname || ""
    if(location !== "/scanner"){
      if (JSON.parse(loginVaild) != true) {
        navigate('/', { replace: true });
      }
    }
  }, [loginRes]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <MemberModalProvider>{content}</MemberModalProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
