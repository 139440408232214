

export const IMAGE_UPLOAD_REQUEST = "IMAGE_UPLOAD_REQUEST";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const IMAGE_UPLOAD_ERROR = "IMAGE_UPLOAD_ERROR";
// export const GET_MEMBER_REQUEST = "GET_MEMBER_REQUEST";
// export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
// export const GET_MEMBER_ERROR = "GET_MEMBER_ERROR";

export interface ImageUploadRequest {
    type: typeof IMAGE_UPLOAD_REQUEST,
}

export interface ImageUploadError {
    type: typeof IMAGE_UPLOAD_ERROR,
}

export interface ImageUploadSuccess {
    type: typeof IMAGE_UPLOAD_SUCCESS,
    payload: []
}

export interface ImageUploadType {
}

export type ImageUploadDispatchTypes = ImageUploadRequest | ImageUploadSuccess | ImageUploadError;
