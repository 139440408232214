import { combineReducers } from 'redux';
import RestaurantReducer from './RestaurantReducer';
import EventsReducer from './EventsReducer';
import ImageUploadsReducer from './ImagesUploadReducer';
import MemberReducer from './MemberReducer';
import StatesReducer from './StateReducer';
import RestaurantGroupReducer from './RestaurantGroupReducer';
import DistrictReducer from './SelectedDistrictReducer';
import DeleteByIdReducer from './DeleteByIdReducer';
import GetByIdReducer from './GetByIdReducer';
import LoginReducer from './LoginTypeReducer';
import MemberTypeReducer from './MemberTypeReducer';
import RestaurantDiscountReducer from './RestaurantDiscountReducer';
import EventImageUploadsReducer from './EventImagesUploadReducer';
import RestaurantEditReducer from './RestaurantEditReducer';
import NotificationReducer from './NotificationFormReducer';
import QueryReducer from './QueryReducer';
import UnverifiedMemberReducer from './UnverifiedMemberListReducer';
import PreferenceTagReducer from './PreferenceTagReducer';
import PreferenceReducer from './PreferenceReducuer';

// for mobile screen
import UserReducer from '../mobile_redux/reducers/UserReducer';
import DropdownReducer from '../mobile_redux/reducers/RestaurantDropdownReducer';
import OTPSubmitReducer from '../mobile_redux/reducers/OTPSubmitReducer';
import OTPValidateReducer from '../mobile_redux/reducers/OTPValidateReducer';
import FormDataReducer from '../mobile_redux/reducers/FormDataReducer';

const RootReducer = combineReducers({
  LoginReducer,
  RestaurantReducer,
  MemberReducer,
  StatesReducer,
  DistrictReducer,
  EventsReducer,
  DeleteByIdReducer,
  GetByIdReducer,
  RestaurantGroupReducer,
  MemberTypeReducer,
  RestaurantDiscountReducer,
  EventImageUploadsReducer,
  RestaurantEditReducer,
  NotificationReducer,

  ImageUploadsReducer,
  UserReducer,
  DropdownReducer,
  OTPSubmitReducer,
  OTPValidateReducer,
  FormDataReducer,
  QueryReducer,
  UnverifiedMemberReducer,
  PreferenceTagReducer,
  PreferenceReducer
  // entities,
  // login: LoginReducer,
});

export default RootReducer;
