export const RESTAURANT_DISCOUNT_REQUEST = "RESTAURANT_DISCOUNT_REQUEST";
export const RESTAURANT_DISCOUNT_SUCCESS = "RESTAURANT_DISCOUNT_SUCCESS";
export const RESTAURANT_DISCOUNT_ERROR = "RESTAURANT_DISCOUNT_ERROR";

export interface RestaurantDiscountRequest {
    type: typeof RESTAURANT_DISCOUNT_REQUEST,
}

export interface RestaurantDiscountError {
    type: typeof RESTAURANT_DISCOUNT_ERROR,
    payload: ''
}

export interface RestaurantDiscountSuccess {
    type: typeof RESTAURANT_DISCOUNT_SUCCESS,
    payload: {
        name: string,
        discount: number,
        discountStartDate: string,
        discountEndDate: string,
        isActive: boolean,
        isDeleted: boolean,
        billAmount: number,
        createdById: string,
        updatedById: string,
        restaurantsId: number
    }
}

export interface RestaurantDiscountType {
    name: string,
    discount: number,
    discountStartDate: string,
    discountEndDate: string,
    isActive: boolean,
    isDeleted: boolean,
    billAmount: number,
    createdById: string,
    updatedById: string,
    restaurantsId: number
}

export type RestaurantDiscountDispatchTypes = RestaurantDiscountRequest | RestaurantDiscountSuccess | RestaurantDiscountError;
