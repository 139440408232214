import React from 'react'
import { Action } from 'redux';
import {
    GET_DISTRICT_REQUEST,
    GET_DISTRICT_SUCCESS,
    GET_DISTRICT_ERROR,
    DistrictType,
    DistrictDispatchTypes
} from '../constants/SelectedDistrictActionType'


interface DefaultState {
    loading: boolean,
    district?: DistrictType,
}


const initialState: DefaultState = {
    loading: false,
}


const DistrictReducer = (state: DefaultState = initialState, action: DistrictDispatchTypes): DefaultState => {
    switch (action.type) {
        case GET_DISTRICT_REQUEST:
            return {
                // ...state,
                loading: true
            }

        case GET_DISTRICT_SUCCESS:
            return {
                // ...state,
                loading: false,
                district: action.payload,

            }

        case GET_DISTRICT_ERROR:
            return {
                // ...state,
                loading: false,
                // error: action.payload
            }

        default:
            return state
            break;
    }
}

export default DistrictReducer