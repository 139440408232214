import { IconButton } from '@mui/material';

// Submenus

export const DashboardCustomIcon: () => JSX.Element = () => (
  <IconButton style={{ padding: '0px' }}>
    <img style={{ width: '10px' }} alt="edit" src="/static/images/icons/dashboard.svg" />
  </IconButton>
)

export const RestaurantCustomIcon: () => JSX.Element = () => (
  <IconButton style={{ padding: '0px' }}>
    <img style={{ width: '15px' }} alt="edit" src="/static/images/icons/restaurant.svg" />
  </IconButton>
)

export const EventsCustomIcon: () => JSX.Element = () => (
  <IconButton style={{ padding: '0px' }}>
    <img style={{ width: '15px' }} alt="edit" src="/static/images/icons/events.svg" />
  </IconButton>
)

export const MembersCustomIcon: () => JSX.Element = () => (
  <IconButton style={{ padding: '0px' }}>
    <img style={{ width: '15px' }} alt="edit" src="/static/images/icons/members.svg" />
  </IconButton>
)

// Dashboard

export const DashboardCardMemberSaving: () => JSX.Element = () => (
  <IconButton style={{ padding: '0px' }}>
    <img style={{ width: '15px' }} alt="edit" src="/static/images/icons/rupees.svg" />
  </IconButton>
)

export const DashboardCardRestaurant: () => JSX.Element = () => (
  <IconButton style={{ padding: '0px' }}>
    <img style={{ width: '15px' }} alt="edit" src="/static/images/icons/dashboard-card-restaurant.svg" />
  </IconButton>
)
export const DashboardCardEvents: () => JSX.Element = () => (
  <IconButton style={{ padding: '0px' }}>
    <img style={{ width: '15px' }} alt="edit" src="/static/images/icons/dashboard-card-events.svg" />
  </IconButton>
)
export const DashboardCardMembers: () => JSX.Element = () => (
  <IconButton style={{ padding: '0px' }}>
    <img style={{ width: '15px' }} alt="edit" src="/static/images/icons/dashboard-card-members.svg" />
  </IconButton>
)