export const EDIT_RESTAURANT_REQUEST = "EDIT_RESTAURANT_REQUEST";
export const EDIT_RESTAURANT_SUCCESS = "EDIT_RESTAURANT_SUCCESS";
export const EDIT_RESTAURANT_ERROR = "EDIT_RESTAURANT_ERROR";

export interface RestaurantEditRequest {
    type: typeof EDIT_RESTAURANT_REQUEST,
}

export interface RestaurantEditError {
    type: typeof EDIT_RESTAURANT_ERROR,
    error: string,
}

export interface RestaurantEditSuccess {
    type: typeof EDIT_RESTAURANT_SUCCESS,
    payload: {
        name: string,
        contactperson: string,
        phonenumber: number,
        cin: string,
        gst: string,
        pan: string,
        uniqueid: string,
        imahge: string,
        pincode: number,
        location: string,
        createdAt: string,
        updatedAt: string,
        remarks: string,
        restaurantsGroupsId: number,
        userId: string,
        districtsId: number,
        createdById: string,
        updatedById: string
    }
}

export interface RestaurantEditType {
    name: string,
    contactperson: string,
    phonenumber: number,
    cin: string,
    gst: string,
    pan: string,
    uniqueid: string,
    imahge: string,
    pincode: number,
    location: string,
    createdAt: string,
    updatedAt: string,
    remarks: string,
    restaurantsGroupsId: number,
    userId: string,
    districtsId: number,
    createdById: string,
    updatedById: string
}

export type RestaurantEditDispatchTypes = RestaurantEditRequest | RestaurantEditSuccess | RestaurantEditError;
