export const GET_OTP_SUBMIT_REQUEST = "GET_OTP_SUBMIT_REQUEST";
export const GET_OTP_SUBMIT_SUCCESS = "GET_OTP_SUBMIT_SUCCESS";
export const GET_OTP_SUBMIT_ERROR = "GET_OTP_SUBMIT_ERROR";

export interface OTPSubmitRequest {
    type: typeof GET_OTP_SUBMIT_REQUEST,
}

export interface OTPSubmitError {
    type: typeof GET_OTP_SUBMIT_ERROR,
    error: ''
}

export interface OTPSubmitSuccess {
    type: typeof GET_OTP_SUBMIT_SUCCESS,
    payload: {
        login: string,
        otp: number,
        password: string
    }
}

export interface OTPSubmitType {
    login: string,
    otp: number,
    password: string
}

export type OTPSubmitDispatchTypes = OTPSubmitRequest | OTPSubmitSuccess | OTPSubmitError;
