import React from 'react'

const changeDateFormat = (inputDateStr:any) => {
    const dateObj = new Date(inputDateStr);

    // Step 2: Get day, month, and year from the Date object
    const day = dateObj.getUTCDate();
    const month = dateObj.getUTCMonth() + 1; // Months are zero-based, so add 1
    const year = dateObj.getUTCFullYear();

    // Step 3: Create formatted date string
    const formattedDateStr = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year.toString()}`;
    return formattedDateStr;
}

export default changeDateFormat;