import React from 'react'
import { Action } from 'redux';
import {
    EVENT_IMAGE_UPLOAD_REQUEST,
    EVENT_IMAGE_UPLOAD_SUCCESS,
    EVENT_IMAGE_UPLOAD_ERROR,
    EventImageUploadType,
    EventImageUploadDispatchTypes
} from '../constants/EventImgesUrlActionType'


interface DefaultState {
    loading: boolean,
    imageUpload?: EventImageUploadType,
    error: string,
}


const initialState: DefaultState = {
    loading: false,
    error: '',
}


const EventImageUploadsReducer = (state: DefaultState = initialState, action: EventImageUploadDispatchTypes): DefaultState => {
    switch (action.type) {
        case EVENT_IMAGE_UPLOAD_REQUEST:
            return {
                ...state,
                loading: true
            }

        case EVENT_IMAGE_UPLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                imageUpload: action.payload,

            }

        case EVENT_IMAGE_UPLOAD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state
            break;
    }
}

export default EventImageUploadsReducer