export const GET_MEMBER_TYPE_REQUEST = "GET_MEMBER_TYPE_REQUEST";
export const GET_MEMBER_TYPE_SUCCESS = "GET_MEMBER_TYPE_SUCCESS";
export const GET_MEMBER_TYPE_ERROR = "GET_MEMBER_TYPE_ERROR";

export interface MemberTypeRequest {
    type: typeof GET_MEMBER_TYPE_REQUEST,
}

export interface MemberTypeError {
    type: typeof GET_MEMBER_TYPE_ERROR,
    payload: ''
}

export interface MemberTypeSuccess {
    type: typeof GET_MEMBER_TYPE_SUCCESS,
    payload: {
        id: number,
        membertype: string,
        isdeleted: boolean
    },
    totalCount: any
}

export interface MemberType_Type {
    id: number,
    membertype: string,
    isdeleted: boolean
}

export type MemberTypeDispatchTypes = MemberTypeRequest | MemberTypeSuccess | MemberTypeError;
