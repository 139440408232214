export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export interface LoginTypeRequest {
    type: typeof LOGIN_REQUEST,
}

export interface LoginTypeError {
    type: typeof LOGIN_ERROR,
    payload: ''
}

export interface LoginTypeSuccess {
    type: typeof LOGIN_SUCCESS,
    payload: []
}

export interface LoginType {
    login: string,
    otp: number,
    password: string
}

export type LoginTypeDispatchTypes = LoginTypeRequest | LoginTypeSuccess | LoginTypeError;
