import { number, string } from "prop-types";
import { boolean } from "yup";


export const GET_RESTAURANT_REQUEST = "GET_RESTAURANT_REQUEST";
export const GET_RESTAURANT_SUCCESS = "GET_RESTAURANT_SUCCESS";
export const GET_RESTAURANT_ERROR = "GET_RESTAURANT_ERROR";
// export const GET_MEMBER_REQUEST = "GET_MEMBER_REQUEST";
// export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
// export const GET_MEMBER_ERROR = "GET_MEMBER_ERROR";

export interface RestaurantRequest {
    type: typeof GET_RESTAURANT_REQUEST,
}

export interface RestaurantError {
    type: typeof GET_RESTAURANT_ERROR,
}

const dicountObj = {
    billAmount: number,
    createdAt: string,
    createdById: string,
    discount: number,
    discountEndDate: string,
    discountStartDate: string,
    id: number,
    isActive: boolean,
    isDeleted: boolean,
    name: string,
    restaurantsId: string,
    updatedAt: string,
    updatedById: string,
}

export interface RestaurantSuccess {
    type: typeof GET_RESTAURANT_SUCCESS,
    payload: {
        id: number,
        name: string,
        contactperson: string,
        phonenumber: number,
        cin: string,
        gst: string,
        pan: string,
        uniqueid: string,
        imahge: string,
        pincode: number,
        location: string,
        createdAt: string,
        updatedAt: string,
        remarks: string,
        restaurantsGroupsId: number,
        userId: string,
        districtsId: number,
        createdById: string,
        updatedById: string
        discounts: Array<typeof dicountObj>,
        restaurantsGroups: {
            escription: string
            districtsId: string
            id: number
            isDeleted: boolean
            location: string
            name: string
            remarks: string
        },
    },
    totalCount: any
}

export interface RestaurantType {
    id: number,
    name: string,
    contactperson: string,
    phonenumber: number,
    cin: string,
    gst: string,
    pan: string,
    uniqueid: string,
    imahge: string,
    pincode: number,
    location: string,
    createdAt: string,
    updatedAt: string,
    remarks: string,
    restaurantsGroupsId: number,
    userId: string,
    districtsId: number,
    createdById: string,
    updatedById: string
    discounts: Array<typeof dicountObj>,
    restaurantsGroups: {
        escription: string
        districtsId: string
        id: number
        isDeleted: boolean
        location: string
        name: string
        remarks: string
    },
}

export type RestaurantDispatchTypes = RestaurantRequest | RestaurantSuccess | RestaurantError;
