export const GET_REQUEST = "GET_REQUEST";
export const GET_SUCCESS = "GET_SUCCESS";
export const GET_ERROR = "GET_ERROR";

export interface GetRequest {
    type: typeof GET_REQUEST,
}

export interface GetError {
    type: typeof GET_ERROR,
}

export interface GetSuccess {
    type: typeof GET_SUCCESS,
    payload: ''
}

export interface GetType {
}

export type GetDispatchTypes = GetRequest | GetSuccess | GetError;
