import axios from 'axios';
import { useEffect, useState } from 'react';
import { base_URL } from 'src/redux/constants/_URL';

interface MemberTransaction {
  id: string;
  memberid: string;
  billamount: string;
  finalamount: string;
  rewardspoints: string;
  createdat: string;
  updatedat: string;
  hotelname:string;
  restaurants: {
    name: string;
    id: string;
  };
}

const useMemberTransactions = (memberId: number) => {
  const [transactions, setTransactions] = useState<MemberTransaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async (id: number) => {
      setLoading(true);
      try {
        const response = await axios.get(`${base_URL}/myrewardpoints/${id}`);
        if (!response.data) throw new Error('Transactions not found');
        setTransactions(response.data);
      } catch (e) {
        setError(e.message);
      }
      setLoading(false);
    };

    fetchTransactions(memberId);
  }, []);

  return { transactions, loading, error };
};

export default useMemberTransactions;
