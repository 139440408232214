import React from 'react'
import { Action } from 'redux';
import {
    EDIT_RESTAURANT_REQUEST,
    EDIT_RESTAURANT_SUCCESS,
    EDIT_RESTAURANT_ERROR,
    RestaurantEditType,
    RestaurantEditDispatchTypes
} from '../constants/RestaurantEditActionType'


interface DefaultState {
    loading: boolean,
    editResponse?: RestaurantEditType,
    editError: string
}


const initialState: DefaultState = {
    loading: false,
    editError: ''
}


const RestaurantEditReducer = (state: DefaultState = initialState, action: RestaurantEditDispatchTypes): DefaultState => {
    switch (action.type) {
        case EDIT_RESTAURANT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case EDIT_RESTAURANT_SUCCESS:
            return {
                ...state,
                loading: false,
                editResponse: action.payload,

            }

        case EDIT_RESTAURANT_ERROR:
            return {
                ...state,
                loading: false,
                editError: action.error
            }

        default:
            return state
            break;
    }
}

export default RestaurantEditReducer