export const GET_RESTAURANT_GROUP_REQUEST = "GET_RESTAURANT_GROUP_REQUEST";
export const GET_RESTAURANT_GROUP_SUCCESS = "GET_RESTAURANT_GROUP_SUCCESS";
export const GET_RESTAURANT_GROUP_ERROR = "GET_RESTAURANT_GROUP_ERROR";

export interface RestaurantGroupRequest {
    type: typeof GET_RESTAURANT_GROUP_REQUEST,
}

export interface RestaurantGroupError {
    type: typeof GET_RESTAURANT_GROUP_ERROR,
}

export interface RestaurantGroupSuccess {
    type: typeof GET_RESTAURANT_GROUP_SUCCESS,
    payload: {
        id: number,
        name: string,
        location: string,
        description: string,
        remarks: string,
        districtsId: number
    },
    totalCount: any
}

export interface RestaurantGroupType {
    id: number,
    name: string,
    location: string,
    description: string,
    remarks: string,
    districtsId: number
}

export type RestaurantGroupDispatchTypes = RestaurantGroupRequest | RestaurantGroupSuccess | RestaurantGroupError;
