export const GET_STATE_REQUEST = "GET_STATE_REQUEST";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";
export const GET_STATE_ERROR = "GET_STATE_ERROR";

export interface StateRequest {
    type: typeof GET_STATE_REQUEST,
}

export interface StateError {
    type: typeof GET_STATE_ERROR,
}

export interface StateSuccess {
    type: typeof GET_STATE_SUCCESS,
    payload: {
        id: number,
        name: string
    }
}

export interface StateType {
    id: number,
    name: string
}

export type StateDispatchTypes = StateRequest | StateSuccess | StateError;
