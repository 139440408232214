export const GET_MEMBER_REQUEST = 'GET_MEMBER_REQUEST';
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_ERROR = 'GET_MEMBER_ERROR';

export interface MemberRequest {
  type: typeof GET_MEMBER_REQUEST;
}

export interface MemberError {
  type: typeof GET_MEMBER_ERROR;
}

export interface MemberSuccess {
  type: typeof GET_MEMBER_SUCCESS;
  payload: {
    id: number;
    email: string;
    dob: string;
    image: string;
    address: string;
    pincode: number;
    remarks: string;
    firstname: string;
    lastname: string;
    memberid: number;
    isVerified: boolean;
    isActivated: boolean;
    isDeleted: boolean;
    startDate: string;
    endDate: string;
    createdAt: string;
    userId: string;
    districtsId: number;
    createdById: string;
    updatedById: string;
    mobile: number;
    updatedAt: string;
    uuid: string;
    membertypeid: number;
    anniversarydate: string;
  };
  totalCount: any;
}

export interface MemberType {
  id: number;
  email: string;
  dob: string;
  image: string;
  address: string;
  pincode: number;
  remarks: string;
  firstname: string;
  lastname: string;
  memberid: number;
  isVerified: boolean;
  isActivated: boolean;
  isDeleted: boolean;
  startDate: string;
  endDate: string;
  createdAt: string;
  userId: string;
  districtsId: number;
  createdById: string;
  updatedById: string;
  mobile: number;
  updatedAt: string;
  uuid: string;
  membertypeid: number;
  anniversarydate: string;
  membertype?: {
    id: number;
    membertype: string;
  };
}

export type MemberDispatchTypes = MemberRequest | MemberSuccess | MemberError;
