import React, { createContext, useState } from 'react';
import MemberProfileModal from 'src/content/applications/Member/MemberProfile';

export const MemberModalContext = createContext<{
  memberId: number;
  setMemberId: React.Dispatch<React.SetStateAction<number>>;
}>(null);

interface Props {
  children: React.ReactNode;
}

const MemberModalProvider = (props: Props) => {
  const [memberId, setMemberId] = useState<number>(null);
  return (
    <MemberModalContext.Provider value={{ memberId, setMemberId }}>
      {props.children}
      {memberId && (
        <MemberProfileModal
          memberId={memberId}
          handleClose={() => setMemberId(null)}
        />
      )}
    </MemberModalContext.Provider>
  );
};

export default MemberModalProvider;
