import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import IosShareIcon from '@mui/icons-material/IosShare';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Text from 'src/components/Text';
import useMember from 'src/hooks/useMember';
import useMemberExport from 'src/hooks/useMemberExport';
import MemberTransactions from './MemberTransactions';
import changeDateFormat from './changeDateFormat';

const AvatarWrapper = styled(Card)(
  ({ theme }) => `

    position: relative;
    overflow: visible;
    display: inline-block;
    margin-top: ${theme.spacing(0)};
    margin-left: ${theme.spacing(2)};

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const CenterBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
`
);

const GridRow = (props: { label: string; value: string | number }) => (
  <Grid container spacing={0}>
    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
      <Box pr={3} pb={2}>
        {props.label}
      </Box>
    </Grid>
    <Grid item xs={12} sm={8} md={9}>
      <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
        <Text color="black">
          <b>{props.value}</b>
        </Text>
      </Box>
    </Grid>
  </Grid>
);

const Modal = (props: {
  handleClose: () => void;
  children: React.ReactNode;
}) => (
  <Dialog
    PaperProps={{
      sx: { backgroundColor: 'rgb(242, 245, 249)', minHeight: '400px' }
    }}
    fullWidth
    maxWidth="xl"
    open
    onClose={props.handleClose}
  >
    {props.children}
  </Dialog>
);

interface Props {
  memberId: number;
  handleClose: () => void;
}

export default function MemberProfileModal(props: Props) {
  const { handleClose, memberId } = props;

  const { member, loading, error } = useMember(memberId);
  const { exportMember, loading: exportLoading } = useMemberExport();
  const backIcon = (
    <Tooltip arrow placement="top" title="Go back">
      <IconButton color="primary" sx={{ p: 2, mr: 2 }} onClick={handleClose}>
        <ArrowBackTwoToneIcon />
      </IconButton>
    </Tooltip>
  );

  if (loading) {
    return (
      <Modal handleClose={handleClose}>
        <DialogContent>
          {backIcon}
          <CenterBox height={300}>
            <CircularProgress />
          </CenterBox>
        </DialogContent>
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal handleClose={handleClose}>
        <DialogContent>
          {backIcon}

          {error}
        </DialogContent>
      </Modal>
    );
  }

  console.log('member',member)

  return (
    <Modal handleClose={handleClose}>
      <DialogContent>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" mb={3}>
            {backIcon}
            <Box>
              <Typography variant="h3" component="h3" gutterBottom>
                Profile for {member.firstname}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              style={{ marginLeft: '10px' }}
              sx={{ mt: { xs: 2, md: 0 } }}
              variant="contained"
              startIcon={
                exportLoading ? (
                  <CircularProgress size="20px" />
                ) : (
                  <IosShareIcon fontSize="small" />
                )
              }
              onClick={() => {
                exportMember(
                  memberId,
                  `${member.firstname}-${member.memberid}`
                );
              }}
              disabled={exportLoading}
            >
              Export
            </Button>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Box>
        </Box>

        <Grid container>
          <Grid item sm={2}>
            <CenterBox mb={2}>
              <AvatarWrapper>
                <Avatar
                  variant="rounded"
                  alt={member.firstname}
                  src={member.image}
                />
              </AvatarWrapper>
            </CenterBox>
          </Grid>
          <Grid item sm={10}>
            <Card>
              <Box
                p={3}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h4" gutterBottom>
                    Personal Details
                  </Typography>
                  <Typography variant="subtitle2">
                    Informations related to your personal details
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <CardContent sx={{ p: 4 }}>
                <Typography variant="subtitle2">
                  <Grid container spacing={0}>
                    <GridRow label="Member ID:" value={member.memberid} />
                    <GridRow
                      label="Full Name:"
                      value={`${member.firstname} ${member.lastname}`}
                    />
                      <GridRow
                      label="Created Time:"
                      value={member.createdAt && changeDateFormat(member.createdAt)}
                    />
                      <GridRow
                      label="Start Date:"
                      value={member.startDate && changeDateFormat(member.startDate)}
                    />
                     <GridRow
                      label="End Date:"
                      value={member.startDate && changeDateFormat(member.endDate)}
                    />
                    <GridRow label="Email ID:" value={member.email} />
                    <GridRow label="Contact Number:" value={member.mobile} />
                    {member.dob && (
                      <GridRow
                        label="DOB:"
                        value={moment(member.dob).format('DD/MM/YYYY')}
                      />
                    )}
                    {member.address && (
                      <GridRow label="Address:" value={member.address} />
                    )}
                    {member.pincode && (
                      <GridRow label="Pincode:" value={member.pincode} />
                    )}
                    {member.membertype?.membertype && (
                      <GridRow
                        label="Member Type:"
                        value={member.membertype?.membertype}
                      />
                    )}
                  </Grid>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Card sx={{ marginTop: '16px' }}>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                Transaction History
              </Typography>
              <Typography variant="subtitle2">
                Informations related to your money spent
              </Typography>
            </Box>
          </Box>
          <Divider />
          <CardContent sx={{ p: 4 }}>
            <MemberTransactions memberId={memberId} />
          </CardContent>
        </Card>
      </DialogContent>

    </Modal>
  );
}
