export const DELETE_REQUEST = "DELETE_REQUEST";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_ERROR = "DELETE_ERROR";

export interface DeleteRequest {
    type: typeof DELETE_REQUEST,
}

export interface DeleteError {
    type: typeof DELETE_ERROR,
}

export interface DeleteSuccess {
    type: typeof DELETE_SUCCESS,
    payload: ''
}

export interface DeleteType {
}

export type DeleteDispatchTypes = DeleteRequest | DeleteSuccess | DeleteError;
