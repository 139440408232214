

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
// export const GET_MEMBER_REQUEST = "GET_MEMBER_REQUEST";
// export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
// export const GET_MEMBER_ERROR = "GET_MEMBER_ERROR";

export interface UserRequest{
    type : typeof GET_USER_REQUEST,
}

export interface UserError{
    type : typeof GET_USER_ERROR,
}

export interface UserSuccess{
    type : typeof GET_USER_SUCCESS,
    payload:{
        id: number,
        email: string,
        dob: string,
        image: string,
        address: string,
        pincode: number,
        remarks: string,
        firstname: string,
        lastname: string,
        memberid: number,
        isVerified: boolean,
        isActivated: boolean,
        isDeleted: boolean,
        startDate: string,
        endDate: string,
        createdAt: string,
        userId: string,
        districtsId: number,
        createdById: number,
        updatedById: number,
        mobile: number,
        updatedAt: string,
        uuid: string,
        membertypeid: number,
        anniversarydate: null,
        tempurl: string
    }
}

export interface UserType{
    id: number,
    email: string,
    dob: string,
    image: string,
    address: string,
    pincode: number,
    remarks: string,
    firstname: string,
    lastname: string,
    memberid: number,
    isVerified: boolean,
    isActivated: boolean,
    isDeleted: boolean,
    startDate: string,
    endDate: string,
    createdAt: string,
    userId: string,
    districtsId: number,
    createdById: number,
    updatedById: number,
    mobile: number,
    updatedAt: string,
    uuid: string,
    membertypeid: number,
    anniversarydate: null,
    tempurl: string
}

export type UserDispatchTypes = UserRequest | UserSuccess | UserError;
