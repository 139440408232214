export const GET_PREFERENCE_REQUEST = 'GET_PREFERENCE_REQUEST';
export const GET_PREFERENCE_SUCCESS = 'GET_PREFERENCE_SUCCESS';
export const GET_PREFERENCE_ERROR = 'GET_PREFERENCE_ERROR';

export interface PreferenceRequest {
  type: typeof GET_PREFERENCE_REQUEST;
}

export interface PreferenceError {
  type: typeof GET_PREFERENCE_ERROR;
}

export interface PreferenceSuccess {
  type: typeof GET_PREFERENCE_SUCCESS;
  payload: {
    id: number;
    name: string;
    remarks: string;
    isveg: boolean;
  };
  totalCount: any;
}

export interface PreferenceType {
  id: number;
  name: string;
  remarks: string;
  isveg: boolean;
}

export type PreferenceDispatchTypes =
  | PreferenceRequest
  | PreferenceSuccess
  | PreferenceError;
