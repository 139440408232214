export const GET_PREFERENCETAG_REQUEST = 'GET_PREFERENCETAG_REQUEST';
export const GET_PREFERENCETAG_SUCCESS = 'GET_PREFERENCETAG_SUCCESS';
export const GET_PREFERENCETAG_ERROR = 'GET_PREFERENCETAG_ERROR';

export interface PreferenceTagRequest {
  type: typeof GET_PREFERENCETAG_REQUEST;
}

export interface PreferenceTagError {
  type: typeof GET_PREFERENCETAG_ERROR;
}

export interface PreferenceTagSuccess {
  type: typeof GET_PREFERENCETAG_SUCCESS;
  payload: {
    id: number;
    name: string;
    description: string;
    mastertagid: string;
  };
  totalCount: any;
}

export interface PreferenceTagType {
  id: number;
  name: string;
  description: string;
  mastertagid: string;
}

export type PreferenceTagDispatchTypes =
  | PreferenceTagRequest
  | PreferenceTagSuccess
  | PreferenceTagError;
