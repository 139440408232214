export const GET_FORM_DATA_REQUEST = "GET_FORM_DATA_REQUEST";
export const GET_FORM_DATA_SUCCESS = "GET_FORM_DATA_SUCCESS";
export const GET_FORM_DATA_ERROR = "GET_FORM_DATA_ERROR";

export interface FormDataRequest {
    type: typeof GET_FORM_DATA_REQUEST,
}

export interface FormDataError {
    type: typeof GET_FORM_DATA_ERROR,
    payload: {
        message: string
    }
}

export interface FormDataSuccess {
    type: typeof GET_FORM_DATA_SUCCESS,
    payload: {
        memberid: number,
        restaurantid: number,
        billamount: number,
        discountid: number,
        finalamount: number,
        otp: number,
        isotpvalidated: boolean,
        restaurantuniqueid: string
    }
}

export interface FormDataType {
    memberid: number,
    restaurantid: number,
    billamount: number,
    discountid: number,
    finalamount: number,
    otp: number,
    isotpvalidated: boolean,
    restaurantuniqueid: string
}

export type FormDataDispatchTypes = FormDataRequest | FormDataSuccess | FormDataError;
