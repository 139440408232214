import React from 'react';
import { Action } from 'redux';
import {
  GET_QUERY_ERROR,
  GET_QUERY_REQUEST,
  GET_QUERY_SUCCESS,
  QuryDispatchTypes,
  QuryType
} from '../constants/QueryActionType';

interface DefaultState {
  loading: boolean;
  queryList?: QuryType;
  totalCount?: any;
}

const initialState: DefaultState = {
  loading: false,
  totalCount: 0
};

const QueryReducer = (
  state: DefaultState = initialState,
  action: QuryDispatchTypes
): DefaultState => {
  switch (action.type) {
    case GET_QUERY_REQUEST:
      return {
        // ...state,
        loading: true
      };

    case GET_QUERY_SUCCESS:
      return {
        // ...state,
        loading: false,
        totalCount: action.totalCount,
        queryList: action.payload
      };

    case GET_QUERY_ERROR:
      return {
        // ...state,
        loading: false
        // error: action.payload
      };

    default:
      return state;
      break;
  }
};

export default QueryReducer;
