export const EVENT_IMAGE_UPLOAD_REQUEST = "EVENT_IMAGE_UPLOAD_REQUEST";
export const EVENT_IMAGE_UPLOAD_SUCCESS = "EVENT_IMAGE_UPLOAD_SUCCESS";
export const EVENT_IMAGE_UPLOAD_ERROR = "EVENT_IMAGE_UPLOAD_ERROR";

export interface EventImageUploadRequest {
    type: typeof EVENT_IMAGE_UPLOAD_REQUEST,
}

export interface EventImageUploadError {
    type: typeof EVENT_IMAGE_UPLOAD_ERROR,
    error: ''
}

export interface EventImageUploadSuccess {
    type: typeof EVENT_IMAGE_UPLOAD_SUCCESS,
    payload: {
        imageurl: string,
        isdeleted: boolean,
        eventId: number,
        eventsId: number
    }
}

export interface EventImageUploadType {
    imageurl: string,
    isdeleted: boolean,
    eventId: number,
    eventsId: number
}

export type EventImageUploadDispatchTypes = EventImageUploadRequest | EventImageUploadSuccess | EventImageUploadError;
