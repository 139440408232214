

export const GET_DROPDOWN_REQUEST = "GET_DROPDOWN_REQUEST";
export const GET_DROPDOWN_SUCCESS = "GET_DROPDOWN_SUCCESS";
export const GET_DROPDOWN_ERROR = "GET_DROPDOWN_ERROR";

export interface DropdownRequest{
    type : typeof GET_DROPDOWN_REQUEST,
}

export interface DropdownError{
    type : typeof GET_DROPDOWN_ERROR,
}

export interface DropdownSuccess{
    type : typeof GET_DROPDOWN_SUCCESS,
    payload:{
        id: number,
        name: string,
        contactperson: string,
        phonenumber: number,
        cin: null,
        gst: null,
        pan: null,
        uniqueid: string,
        imahge: string,
        pincode: number,
        location: string,
        createdAt: string,
        updatedAt: string,
        remarks: null,
        restaurantsGroupsId: string,
        userId: number,
        districtsId: number,
        createdById: number,
        updatedById: number,
        discounts: [
        {
        id: number,
        name: string,
        discount: number,
        discountStartDate: string,
        discountEndDate: string,
        isActive: boolean,
        isDeleted: boolean,
        createdAt: string,
        updatedAt: string,
        billAmount: number,
        createdById: number,
        updatedById: number,
        restaurantsId: number
        }
        ]
    }
}

export interface DropdownType{
    id: number,
    name: string,
    contactperson: string,
    phonenumber: number,
    cin: null,
    gst: null,
    pan: null,
    uniqueid: string,
    imahge: string,
    pincode: number,
    location: string,
    createdAt: string,
    updatedAt: string,
    remarks: null,
    restaurantsGroupsId: string,
    userId: number,
    districtsId: number,
    createdById: number,
    updatedById: number,
    discounts: [
    {
    id: number,
    name: string,
    discount: number,
    discountStartDate: string,
    discountEndDate: string,
    isActive: boolean,
    isDeleted: boolean,
    createdAt: string,
    updatedAt: string,
    billAmount: number,
    createdById: number,
    updatedById: number,
    restaurantsId: number
    }
]
}

export type DropdownDispatchTypes = DropdownRequest | DropdownSuccess | DropdownError;
