import React from 'react';
import { Action } from 'redux';
import {
  GET_MEMBER_REQUEST,
  GET_MEMBER_SUCCESS,
  GET_MEMBER_ERROR,
  MemberType,
  MemberDispatchTypes
} from '../constants/MemberActionType';

interface DefaultState {
  loading: boolean;
  unverifiedMemberList?: MemberType;
  totalCount?: any;
}

const initialState: DefaultState = {
  loading: false,
  totalCount: 0
};

const UnverifiedMemberReducer = (
  state: DefaultState = initialState,
  action: MemberDispatchTypes
): DefaultState => {
  switch (action.type) {
    case GET_MEMBER_REQUEST:
      return {
        // ...state,
        loading: true
      };

    case GET_MEMBER_SUCCESS:
      return {
        // ...state,
        loading: false,
        totalCount: action.totalCount,
        unverifiedMemberList: action.payload
      };

    case GET_MEMBER_ERROR:
      return {
        // ...state,
        loading: false
        // error: action.payload
      };

    default:
      return state;
      break;
  }
};

export default UnverifiedMemberReducer;
