export const GET_OTP_VALIDATE_REQUEST = "GET_OTP_VALIDATE_REQUEST";
export const GET_OTP_VALIDATE_SUCCESS = "GET_OTP_VALIDATE_SUCCESS";
export const GET_OTP_VALIDATE_ERROR = "GET_OTP_VALIDATE_ERROR";

export interface OTPValidateRequest {
    type: typeof GET_OTP_VALIDATE_REQUEST,
}

export interface OTPValidateError {
    type: typeof GET_OTP_VALIDATE_ERROR,
    error: ''
}

export interface OTPValidateSuccess {
    type: typeof GET_OTP_VALIDATE_SUCCESS,
    payload: {
        id: number,
        memberid: number,
        restaurantid: number,
        billamount: number,
        discountid: number,
        finalamount: number,
        otp: number,
        isotpvalidated: boolean,
        createdat: string,
        restaurantuniqueid: string
    }
}

export interface OTPValidateType {
    id: number,
    memberid: number,
    restaurantid: number,
    billamount: number,
    discountid: number,
    finalamount: number,
    otp: number,
    isotpvalidated: boolean,
    createdat: string,
    restaurantuniqueid: string
}

export type OTPValidateDispatchTypes = OTPValidateRequest | OTPValidateSuccess | OTPValidateError;
