import React from 'react'
import { Action } from 'redux';
import {
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    UserType,
    UserDispatchTypes
} from '../constants/UserActionType'


interface DefaultState {
    loading: boolean,
    userDetail?: UserType,
}


const initialState: DefaultState = {
    loading: false,
}


const UserReducer = (state: DefaultState = initialState, action: UserDispatchTypes): DefaultState => {
    switch (action.type) {
        case GET_USER_REQUEST:
            return {
                // ...state,
                loading: true
            }

        case GET_USER_SUCCESS:
            return {
                // ...state,
                loading: false,
                userDetail: action.payload,

            }

        case GET_USER_ERROR:
            return {
                // ...state,
                loading: false,
                // error: action.payload
            }

        default:
            return state
            break;
    }
}

export default UserReducer