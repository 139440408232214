import React from 'react'
import { Action } from 'redux';
import {
    RESTAURANT_DISCOUNT_REQUEST,
    RESTAURANT_DISCOUNT_SUCCESS,
    RESTAURANT_DISCOUNT_ERROR,
    RestaurantDiscountType,
    RestaurantDiscountDispatchTypes
} from '../constants/RestaurantDiscountActionType'


interface DefaultState {
    loading: boolean,
    discountDetail?: RestaurantDiscountType,
    error: string,
}


const initialState: DefaultState = {
    loading: false,
    error: '',
}


const RestaurantDiscountReducer = (state: DefaultState = initialState, action: RestaurantDiscountDispatchTypes): DefaultState => {
    switch (action.type) {
        case RESTAURANT_DISCOUNT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case RESTAURANT_DISCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                discountDetail: action.payload,

            }

        case RESTAURANT_DISCOUNT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return state
            break;
    }
}

export default RestaurantDiscountReducer