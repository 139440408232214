import React from 'react';
import { Action } from 'redux';
import {
  GET_PREFERENCETAG_REQUEST,
  GET_PREFERENCETAG_SUCCESS,
  GET_PREFERENCETAG_ERROR,
  PreferenceTagDispatchTypes,
  PreferenceTagType
} from '../constants/PreferenceTagActionType';

interface DefaultState {
  loading: boolean;
  preferenceTagList?: PreferenceTagType;
  totalCount?: any;
}

const initialState: DefaultState = {
  loading: false,
  totalCount: 0
};

const PreferenceTagReducer = (
  state: DefaultState = initialState,
  action: PreferenceTagDispatchTypes
): DefaultState => {
  switch (action.type) {
    case GET_PREFERENCETAG_REQUEST:
      return {
        // ...state,
        loading: true
      };

    case GET_PREFERENCETAG_SUCCESS:
      return {
        // ...state,
        loading: false,
        totalCount: action.totalCount,
        preferenceTagList: action.payload
      };

    case GET_PREFERENCETAG_ERROR:
      return {
        // ...state,
        loading: false
        // error: action.payload
      };

    default:
      return state;
      break;
  }
};

export default PreferenceTagReducer;
