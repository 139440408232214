import React from 'react'
import { Action } from 'redux';
import {
    DELETE_REQUEST,
    DELETE_SUCCESS,
    DELETE_ERROR,
    // EventsType,
    DeleteDispatchTypes
} from '../constants/DeleteByIDActionType'


interface DefaultState {
    loading: boolean,
    itemDeleted?: '',
}


const initialState: DefaultState = {
    loading: false,
}


const DeleteByIdReducer = (state: DefaultState = initialState, action: DeleteDispatchTypes): DefaultState => {
    switch (action.type) {
        case DELETE_REQUEST:
            return {
                // ...state,
                loading: true
            }

        case DELETE_SUCCESS:
            return {
                // ...state,
                loading: false,
                itemDeleted: action.payload,

            }

        case DELETE_ERROR:
            return {
                // ...state,
                loading: false,
                // error: action.payload
            }

        default:
            return state
            break;
    }
}

export default DeleteByIdReducer