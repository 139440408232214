import React from 'react'
import { Action } from 'redux';
import {
    NOTIFICATION_REQUEST,
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    // EventsType,
    NotificationDispatchTypes
} from '../constants/NotificationForm'


interface DefaultState {
    loading: boolean,
    notifications?: {},
}


const initialState: DefaultState = {
    loading: false,
}


const NotificationReducer = (state: DefaultState = initialState, action: NotificationDispatchTypes): DefaultState => {
    switch (action.type) {
        case NOTIFICATION_REQUEST:
            return {
                // ...state,
                loading: true
            }

        case NOTIFICATION_SUCCESS:
            return {
                // ...state,
                loading: false,
                notifications: action.payload,

            }

        case NOTIFICATION_ERROR:
            return {
                // ...state,
                loading: false,
                // error: action.payload
            }

        default:
            return state
            break;
    }
}

export default NotificationReducer