import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Restaurant = Loader(
  lazy(() => import('src/content/applications/Restaurant'))
);
const Hotels = Loader(
  lazy(() => import('src/content/applications/RestaurantGroup'))
);
const Member = Loader(
  lazy(() => import('src/content/applications/Member'))
);
const UnVerifiedMember = Loader(
  lazy(() => import('src/content/applications/Un-Verified-Member'))
);
const MemberType = Loader(
  lazy(() => import('src/content/applications/MemberType'))
);
const Notifications = Loader(
  lazy(() => import('src/content/applications/Notification'))
);
const Query = Loader(
  lazy(() => import('src/content/applications/Query'))
);
const Preference = Loader(
  lazy(() => import('src/content/applications/Preference'))
);
const PreferenceTags = Loader(
  lazy(() => import('src/content/applications/PreferenceTags'))
);
const Events = Loader(
  lazy(() => import('src/content/applications/Events'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

const HotelsIframe = Loader(lazy(() => import('src/content/applications/Hotels')));
const RestaurantIframe = Loader(lazy(() => import('src/content/applications/RestaurantIframe')));
const EventsIframe = Loader(lazy(() => import('src/content/applications/EventsIframe')));
const MemberIframe = Loader(lazy(() => import('src/content/applications/MemberIframe')));
const PreferencesIframe = Loader(lazy(() => import('src/content/applications/PreferencesIframe')));
const RewardPointsIframe = Loader(lazy(() => import('src/content/applications/RewardPointsIframe')));
const RewardPointsIframeReport = Loader(lazy(() => import('src/content/applications/RewardPointsIframeReport')));

// Mobile
const Scanner = Loader(
  lazy(() => import('src/content/mobileScreen/Scanner'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));



// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="Crypto" replace />
      // },
      {
        path: '',
        element: <Crypto />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'reports',
    element: <Hotels />,
    children: [
      {
        path: '',
        element: <Navigate to="hotels" replace />
      },
      // {
      //   path: 'crypto',
      //   element: <Crypto />
      // },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'scanner',
    element: <Scanner />,
    children: [
      {
        path: '',
        element: <Navigate to="scanner" replace />
      },
      // {
      //   path: 'scanner',
      //   element: <Scanner />
      // },
    ]
  },

  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="Restaurant" replace />
      },
      {
        path: 'scanner',
        element: <Scanner />
      },
      {
        path: 'Restaurant',
        element: <Restaurant />
      },
      {
        path: 'Hotels',
        element: <Hotels />
      },
      {
        path: 'Member',
        element: <Member />
      },

      {
        path: 'tobeverifymember',
        element: < UnVerifiedMember />
      },

      {
        path: 'MemberType',
        element: <MemberType />
      },
      {
        path: 'Notifications',
        element: <Notifications />
      },
      {
        path: 'Query',
        element: <Query />
      },
      {
        path: 'Preference',
        element: <Preference />
      },
      {
        path: 'PreferencesTags',
        element: <PreferenceTags />
      },
      {
        path: 'Events',
        element: <Events />
      },
      {
        path: 'HotelsIframe',
        element: <HotelsIframe />
      },
      {
        path: 'restaurantIframe',
        element: <RestaurantIframe />
      },
      {
        path: 'memberIframe',
        element: <MemberIframe />
      },
      {
        path: 'eventsIframe',
        element: <EventsIframe />
      },
      {
        path: 'preferences',
        element: <PreferencesIframe />
      },
      {
        path: 'rewardPoints',
        element: <RewardPointsIframe />
      },
      {
        path: 'rewardPointsReport',
        element: <RewardPointsIframeReport />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
