import React from 'react'
import { Action } from 'redux';
import {
    GET_STATE_REQUEST,
    GET_STATE_SUCCESS,
    GET_STATE_ERROR,
    StateType,
    StateDispatchTypes
} from '../constants/StateActionType'


interface DefaultState {
    loading: boolean,
    statesList?: StateType,
}


const initialState: DefaultState = {
    loading: false,
}


const StatesReducer = (state: DefaultState = initialState, action: StateDispatchTypes): DefaultState => {
    switch (action.type) {
        case GET_STATE_REQUEST:
            return {
                // ...state,
                loading: true
            }

        case GET_STATE_SUCCESS:
            return {
                // ...state,
                loading: false,
                statesList: action.payload,

            }

        case GET_STATE_ERROR:
            return {
                // ...state,
                loading: false,
                // error: action.payload
            }

        default:
            return state
            break;
    }
}

export default StatesReducer