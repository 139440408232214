export const GET_DISTRICT_REQUEST = "GET_DISTRICT_REQUEST";
export const GET_DISTRICT_SUCCESS = "GET_DISTRICT_SUCCESS";
export const GET_DISTRICT_ERROR = "GET_DISTRICT_ERROR";

export interface DistrictRequest {
    type: typeof GET_DISTRICT_REQUEST,
}

export interface DistrictError {
    type: typeof GET_DISTRICT_ERROR,
}

export interface DistrictSuccess {
    type: typeof GET_DISTRICT_SUCCESS,
    payload: {
        id: number,
        name: string,
        statesId: number
    }
}

export interface DistrictType {
    id: number,
    name: string,
    statesId: number
}

export type DistrictDispatchTypes = DistrictRequest | DistrictSuccess | DistrictError;
