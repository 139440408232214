import {
  FormDataDispatchTypes,
  FormDataType,
  GET_FORM_DATA_ERROR,
  GET_FORM_DATA_REQUEST,
  GET_FORM_DATA_SUCCESS
} from '../constants/FormDataActionType';

interface DefaultState {
  loading: boolean;
  formDataResponse?: FormDataType;
  error: any;
}

const initialState: DefaultState = {
  loading: false,
  error: ''
};

const FormDataReducer = (
  state: DefaultState = initialState,
  action: FormDataDispatchTypes
): DefaultState => {
  switch (action.type) {
    case GET_FORM_DATA_REQUEST:
      return {
        // ...state,
        loading: true,
        error: ''
      };

    case GET_FORM_DATA_SUCCESS:
      return {
        // ...state,
        loading: false,
        error: '',
        formDataResponse: action.payload
      };

    case GET_FORM_DATA_ERROR:
      return {
        // ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
      break;
  }
};

export default FormDataReducer;
