export const GET_QUERY_REQUEST = 'GET_QUERY_REQUEST';
export const GET_QUERY_SUCCESS = 'GET_QUERY_SUCCESS';
export const GET_QUERY_ERROR = 'GET_QUERY_ERROR';

export interface QuryRequest {
  type: typeof GET_QUERY_REQUEST;
}

export interface QuryError {
  type: typeof GET_QUERY_ERROR;
}

export interface QurySuccess {
  type: typeof GET_QUERY_SUCCESS;
  payload: {
    id: number;
    subject: string;
    details: string;
    membersid: string;
    createdat: string;
    updatedat: string;
    reply: string;
  };
  totalCount: any;
}

export interface QuryType {
  id: number;
  subject: string;
  details: string;
  membersid: string;
  createdat: string;
  updatedat: string;
  reply: string;
}

export type QuryDispatchTypes = QuryRequest | QurySuccess | QuryError;
