import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import moment from 'moment';
import { ChangeEvent, useMemo, useState } from 'react';
import useMemberTransactions from 'src/hooks/useMemberTransactions';

const formatMoney = (value: number | string) => {
  if (!value) return '';
  const money = parseFloat(value.toString());
  return `₹${money.toLocaleString()}`;
};

const CenterBox = styled(Box)(
  ({ theme }) => `
      display: flex;
      align-items: center;
      justify-content: center;
  `
);

const TableTypography = styled(Typography)({
  fontSize: '12px',
  textTransform: 'capitalize'
});

interface Props {
  memberId: number;
}

const MemberTransactions = (props: Props) => {
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const { memberId } = props;
  const {
    transactions: data,
    loading,
    error
  } = useMemberTransactions(memberId);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPage(0);
    setLimit(parseInt(event.target.value));
  };

  const transactions = useMemo(() => {
    const offset = page * limit;
    return data.slice(offset, offset + limit);
  }, [page, limit, data]);

  if (loading) {
    return (
      <CenterBox>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (error) {
    return (
      <Typography variant="subtitle2">
        <CenterBox>{error}</CenterBox>
      </Typography>
    );
  }

  if (transactions.length <= 0) {
    return (
      <Typography variant="subtitle2">
        <CenterBox>No History</CenterBox>
      </Typography>
    );
  }

  console.log('transactions',transactions)

  return (
    <>
      <TableContainer className="table-grid">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Transaction Date</TableCell>
              <TableCell>Hotel Name</TableCell>
              <TableCell>Restaurant Name</TableCell>
              <TableCell>Gross Amount</TableCell>
              <TableCell>Final Amount</TableCell>
              <TableCell>Rewards</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((row) => {
              return (
                <TableRow hover key={row.id}>
                  <TableCell>
                    <TableTypography>
                      {row?.createdat
                        ? moment(row.createdat)
                            .subtract(0, 'hours')
                            .subtract(0, 'minutes')
                            .format('DD/MM/YYYY hh:mm A')
                        : ''}
                    </TableTypography>
                  </TableCell>
                  <TableCell sx={{ minWidth: '150px' }}>
                    <TableTypography>
                    {row?.hotelname ?? 'NA'}
                    </TableTypography>
                  </TableCell>
                  <TableCell sx={{ minWidth: '150px' }}>
                    <TableTypography>
                      {row.restaurants?.name || 'NA'}
                    </TableTypography>
                  </TableCell>
                  <TableCell sx={{ minWidth: '150px' }}>
                    <TableTypography>
                      {formatMoney(row.billamount)}
                    </TableTypography>
                  </TableCell>
                  <TableCell sx={{ minWidth: '150px' }}>
                    <TableTypography>
                      {formatMoney(row.finalamount)}
                    </TableTypography>
                  </TableCell>

                  <TableCell sx={{ minWidth: '150px' }}>
                    <TableTypography>
                      {parseFloat(row.rewardspoints).toFixed(2)}
                    </TableTypography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={transactions.length || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[50, 100]}
        />
      </Box>
    </>
  );
};

export default MemberTransactions;
