import React from 'react'
import { Action } from 'redux';
import {
    GET_RESTAURANT_GROUP_REQUEST,
    GET_RESTAURANT_GROUP_SUCCESS,
    GET_RESTAURANT_GROUP_ERROR,
    RestaurantGroupType,
    RestaurantGroupDispatchTypes
} from '../constants/RestaurantGroupActionType'


interface DefaultState {
    loading: boolean,
    restaurantGroupList?: RestaurantGroupType,
    totalCount?: any,
}


const initialState: DefaultState = {
    loading: false,
    totalCount: 0,
}


const RestaurantGroupReducer = (state: DefaultState = initialState, action: RestaurantGroupDispatchTypes): DefaultState => {
    switch (action.type) {
        case GET_RESTAURANT_GROUP_REQUEST:
            return {
                // ...state,
                loading: true
            }

        case GET_RESTAURANT_GROUP_SUCCESS:
            return {
                // ...state,
                loading: false,
                totalCount: action.totalCount,
                restaurantGroupList: action.payload,
            }

        case GET_RESTAURANT_GROUP_ERROR:
            return {
                // ...state,
                loading: false,
                // error: action.payload
            }

        default:
            return state
            break;
    }
}

export default RestaurantGroupReducer