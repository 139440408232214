export const NOTIFICATION_REQUEST = "NOTIFICATION_REQUEST";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";

export interface NotificationRequest {
    type: typeof NOTIFICATION_REQUEST,
}

export interface NotificationError {
    type: typeof NOTIFICATION_ERROR,
}

export interface NotificationSuccess {
    type: typeof NOTIFICATION_SUCCESS,
    payload: {
        subject: string,
        description: string,
        imageurl: string,
        additionalProp1: {}
    }
}

export interface NotificationType {
    subject: string,
    description: string,
    imageurl: string,
    additionalProp1: {}
}

export type NotificationDispatchTypes = NotificationRequest | NotificationSuccess | NotificationError;
