import React from 'react'
import { Action } from 'redux';
import {
    GET_MEMBER_TYPE_REQUEST,
    GET_MEMBER_TYPE_SUCCESS,
    GET_MEMBER_TYPE_ERROR,
    MemberType_Type,
    MemberTypeDispatchTypes
} from '../constants/MemberType'


interface DefaultState {
    loading: boolean,
    memberTypeList?: MemberType_Type,
    error: string,
    totalCount?: any,
}


const initialState: DefaultState = {
    loading: false,
    error: '',
    totalCount: 0,
}


const MemberTypeReducer = (state: DefaultState = initialState, action: MemberTypeDispatchTypes): DefaultState => {
    switch (action.type) {
        case GET_MEMBER_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case GET_MEMBER_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                totalCount: action.totalCount,
                memberTypeList: action.payload,

            }

        case GET_MEMBER_TYPE_ERROR:
            return {
                // ...state,
                loading: false,
                error: action.payload
            }

        default:
            return state
            break;
    }
}

export default MemberTypeReducer