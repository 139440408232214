import React from 'react'
import { Action } from 'redux';
import {
    GET_EVENTS_REQUEST,
    GET_EVENTS_SUCCESS,
    GET_EVENTS_ERROR,
    EventsType,
    EventsDispatchTypes
} from '../constants/EventsActionType'


interface DefaultState {
    loading: boolean,
    eventList?: EventsType,
    totalCount?: any,
}


const initialState: DefaultState = {
    loading: false,
    totalCount: 0,
}


const EventsReducer = (state: DefaultState = initialState, action: EventsDispatchTypes): DefaultState => {
    console.log('actionss',action)
    switch (action.type) {
        case GET_EVENTS_REQUEST:
            return {
                // ...state,
                loading: true
            }

        case GET_EVENTS_SUCCESS:
            return {
                // ...state,
                loading: false,
                totalCount: action.totalCount,
                eventList: action.payload,

            }

        case GET_EVENTS_ERROR:
            return {
                // ...state,
                loading: false,
                // error: action.payload
            }

        default:
            return state
            break;
    }
}

export default EventsReducer