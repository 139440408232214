export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_ERROR = "GET_EVENTS_ERROR";
// export const GET_MEMBER_REQUEST = "GET_MEMBER_REQUEST";
// export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
// export const GET_MEMBER_ERROR = "GET_MEMBER_ERROR";

export interface EventsRequest {
    type: typeof GET_EVENTS_REQUEST,
}

export interface EventsError {
    type: typeof GET_EVENTS_ERROR,
}

export interface EventsSuccess {
    type: typeof GET_EVENTS_SUCCESS,
    payload: {
        id: number,
        name: string,
        startDate: string,
        endDate: string,
        startTime: string,
        endTime: string,
        eventDescription: string,
        location: string,
        pincode: number,
        image: string,
        seats: number,
        remarks: string,
        price: string,
        createdAt: string,
        updatedAt: string,
        extraString: string,
        isActive: boolean,
        isDeleted: boolean,
        createdById: string,
        updatedById: string,
        isregistrationstopped: boolean,
        menuurl: string,
        contactperson: number,
        contactnumber: string,
    }
    totalCount: any
}

export interface EventsType {
    id: number,
    name: string,
    startDate: string,
    endDate: string,
    startTime: string,
    endTime: string,
    eventDescription: string,
    location: string,
    pincode: number,
    image: string,
    seats: number,
    remarks: string,
    price: string,
    createdAt: string,
    updatedAt: string,
    extraString: string,
    isActive: boolean,
    isDeleted: boolean,
    createdById: string,
    updatedById: string,
    isregistrationstopped: boolean,
    menuurl: string,
    contactperson: number,
    contactnumber: string,
}

export type EventsDispatchTypes = EventsRequest | EventsSuccess | EventsError;
