import axios from 'axios';
import fileDownload from 'js-file-download';
import { useState } from 'react';
import { base_URL } from 'src/redux/constants/_URL';

const useMemberExport = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const exportMember = async (id: number, filename = 'member_profile') => {
    setLoading(true);
    try {
      const response = await axios.get(`${base_URL}/members/${id}/download`);
      console.log({ response });
      if (!response.data) throw new Error('Member not found');
      fileDownload(response.data, `${filename}.csv`);
    } catch (e) {
      setError(e.message);
      alert(e.message);
    }
    setLoading(false);
  };

  return { exportMember, loading, error };
};

export default useMemberExport;
