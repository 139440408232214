import React from 'react'
import { Action } from 'redux';
import {
    GET_OTP_SUBMIT_REQUEST,
    GET_OTP_SUBMIT_SUCCESS,
    GET_OTP_SUBMIT_ERROR,
    OTPSubmitType,
    OTPSubmitDispatchTypes
} from '../constants/OTPSubmitActionType'


interface DefaultState {
    loading: boolean,
    otp?: OTPSubmitType,
    error: string
}


const initialState: DefaultState = {
    loading: false,
    error: '',
}


const OTPSubmitReducer = (state: DefaultState = initialState, action: OTPSubmitDispatchTypes): DefaultState => {
    switch (action.type) {
        case GET_OTP_SUBMIT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case GET_OTP_SUBMIT_SUCCESS:
            return {
                ...state,
                loading: false,
                otp: action.payload,

            }

        case GET_OTP_SUBMIT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state
            break;
    }
}

export default OTPSubmitReducer